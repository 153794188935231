* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.ant-table-pagination.ant-pagination {
  margin: 30px !important;
}

.ant-table-cell {
  color: #6a6a6a !important;
  text-align: center !important;
}

.ant-table-row {
  cursor: pointer !important;
}

th.ant-table-cell {
  background-color: #f4f7f9 !important;
  color: #3b8098 !important;
  font-weight: 400 !important;
  text-align: center !important;
  text-transform: uppercase !important;
}

th.ant-table-cell::before {
  background-color: #3b8098 !important;
}

tbody {
  margin-top: 25px !important;
}

.swiper-button-next {
  display: none !important;
}

.swiper-button-prev {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px !important;
}

.ant-image-preview-img {
  margin: 10% auto !important;
  vertical-align: center !important;
  max-width: 400px !important;
}

.ant-image-preview-img-wrapper {
  position: relative !important;
}

.ant-image-preview-img {
  bottom: 30px !important;
}

.active {
  background-color: #131726 !important;
  border: 2px solid #1e253d;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.ant-btn-primary {
  background-color: #116072 !important;
}

.spinner {
  --clr: #242731;
  --gap: 8px;
  /* gap between each circle */
  width: 100px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}
.ant-input-password {
  border: none !important;
  width: 100% !important;
}

.ant-input-affix-wrapper-focused {
  border: none !important;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #242731;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.ant-picker {
  background-color: #fff !important;
}

.ant-image-mask-info {
  display: none !important;
}

.ant-tooltip-inner {
  display: none !important;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
  height: 140px !important;
  margin: 0 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none !important;
}

.ant-form-item-row {
  display: block !important;
}

.ant-form-item-label > label {
  height: 26px !important;
  color: #468597 !important;
}

.ant-form-item {
  margin: 0 !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.promotionModal .ant-modal-content {
  background-color: #e3eaf3;
  border-radius: 10px;
}

.clothes_category_hover2 {
  display: none;
  animation: show-arrow-right-category-clothes 0.5s forwards;
}
.clothes_category_hover:hover .clothes_category_hover2 {
  display: block;
}
.clothes_category_hover:hover .clothes_category_hover1 {
  display: none;
}
@keyframes show-arrow-right-category-clothes {
  0% {
    margin-left: -140px;
  }
  100% {
    margin-left: -40px;
  }
}

.ant-steps {
  display: none !important;
}

.css-dev-only-do-not-override-yp8pcc.ant-steps.ant-steps-vertical {
  display: none !important;
}
